import {Button, Card, Carousel} from 'antd'

import React, {useEffect, useState} from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import { Album } from "../../types";

import './AlbumContainer.less'

export type AlbumContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  album: Album
}

export const AlbumContainer = (props: AlbumContainerProps) => {
  const [image, setImage] = useState<any>()

  const { t } = useTranslation()

  const { album } = props

  useEffect(() => {
    import(`../../assets/photos/${album.id}.jpg`).then((item) => {
      setImage(item.default);
    })
  }, []);

  return (
    <Card
      title={t(`albums.${album.id}`)}
      className="album-card"
      cover={
        <img
          alt="example"
          src={image}
        />
      }
      onClick={() => window.open(album.url, "_blank")}
      hoverable
    >
    </Card>
  )
}
