import React from 'react'

import { useViewport } from '../../hooks/use-viewport'
import { NavBarMobile } from './NavBar.mobile'
import { NavBarDesktop } from './NavBar.desktop'

import './NavBar.less'

export type NavBarProps = React.HTMLAttributes<HTMLDivElement> & {
  language?: string
  setLanguage: React.Dispatch<React.SetStateAction<string>>
  showSignInModal: () => void
  hideSignInModal: () => void
}

export const NavBar = (props: NavBarProps) => {
  const { isMobileView } = useViewport()

  return isMobileView ? (
    <NavBarMobile {...props} />
  ) : (
    <NavBarDesktop {...props} />
  )
}
