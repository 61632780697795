export const EN = {
  'navbar.menu': 'Menu',
  'navbar.item.invitation': 'Invitation',
  'navbar.item.ceremony-plan': 'Ceremony plan',
  'navbar.item.confirm-presence': 'Confirm attendance',
  'navbar.item.choose-present': 'Choose a gift',
  'navbar.item.contact': 'Contact',
  'sign-in.text': 'Sign in',
  'sign-in.button': 'Sign in',
  'sign-in.welcome':
    'Hello! We are pleased that you want to log in. This will allow you to learn more about the wedding, choose a gift or confirm your presence.',
  'sign-in.password-location':
    'You can find the password at the end of the invitation.',
  'sign-in-form.password': 'Password',
  'sign-in-form.password.second-part': 'Password (last 3 signs from green frame)',
  'sign-in-form.validation.too-short-password':
    'Password is too short. Please enter the password from the invitation',
  'sign-in-form.validation.invalid-password':
    'Invalid password. Please enter the password from the invitation',
  'sign-in-modal.header': 'Sign in',
  'sign-in-modal.close': 'Close',
  'sign-in-modal.sign-in': 'Sign in',
  'sign-in.success': 'You have successfully signed in',
  'form.done': 'Data has been sent! Thank you!',
  'user-menu.sign-out': 'Sign out',
  'user-menu.change-password': 'Change password',
  'divider.see-once-again': 'Experience it again!',
  'divider.movies': 'Lights, camera, action!',
  'divider.movie-fragments': 'Special moments',
  'divider.photos': 'Photos',
  'divider.add-your-files': 'Photos and videos uploaded by you',
  'divider.service-providers': 'Our service providers',
  'divider.year-passed': 'It\'s been a year!',
  'divider.thanks': 'Thank you!',
  'divider.best-man-and-woman': 'Matron of honor and best man',
  'divider.dear-guests': 'Dear guests',
  'divider.ceremony-plan': 'Ceremony plan',
  'divider.confirm-presence': 'Confirm your presence',
  'divider.choose-present': 'Choose a gift',
  'divider.contact': 'Contact',
  'divider.route': 'Route',
  'divider.region': 'Welcome to Jura Krakowsko - Częstochowska!',
  'divider.important-notes': 'Important notes',
  'time.day': 'day',
  'time.days': 'days',
  'event.add-to-calendar': 'Add to your calendar',
  'event.do-not-want-to-miss': 'You don\'t want to miss?',
  'event.left': 'LEFT',
  'event.confirm-presence.title': 'CONFIRM PRESENCE',
  'event.confirm-presence.name':
    'Confirm presence - Ola & Sebastian wedding',
  'event.confirm-presence.details':
    'Please confirm your presence. Visit our website to find out more: ',
  'event.wedding.title': 'WEDDING DATE',
  'event.wedding.name':
    'Ola & Sebastian wedding ceremony',
  'event.wedding.details':
    'All information is available on the website: ',
  'event.wedding.location':
    'Kościół Rzymskokatolicki pw. Św. Jana Chrzciciela (John the Baptist Church), Strażacka 9, 42-262 Poczesna, Polska',
  'welcome.text': 'We appreciate that you participated in one of the most important events in our lives. After logging in, you will be able to view all wedding materials. Let\'s remember these moments again!',
  'welcome.list-will-be-closed': 'The attendance list will be closed soon.',
  'welcome.list-is-closed': 'The attendance list has been closed.',
  'welcome.bus-cancelled': 'We remind you that the bus was canceled due to a lack of interest!',
  'welcome.check-presence': 'Please check if everything is correct.',
  'welcome.complete-attendance-form': 'Please complete the attendance form as soon as possible.',
  'welcome.thank-you-for-filling': 'Thank you to everyone who has already confirmed their attendance 😀.',
  'welcome.jura': 'We cordially invite all guests from outside our region to visit it. You can find more information on the websites: ',
  'welcome.sign-in':
    'You can access the rest of the website by logging in. You will find the password on your invitation.',
  'welcome.route': 'ROUTE',
  'welcome.map': 'MAP',
  'welcome.route2': 'Due to the renovation of the DK91 road, access via Handlowa Street in Poczesna is impossible. The easiest alternative is to turn off the DK91 road into road no. 791 (towards Myszków), then turn into Szkolna Street after about 500 m, and then turn right into ul. Bankowa (for about 1 km). The church will be on the new side after driving about 750 meters',
  'ceremony-plan-item.address': 'Address',
  'ceremony-plan-item.people': 'Required people',
  'ceremony-plan.groom-house': 'The groom\'s house',
  'ceremony-plan.bridge-house': 'The bride\'s house',
  'ceremony-plan.church': 'St. John the Baptist Church',
  'ceremony-plan.wedding-hall': '"Złote Piaski" wedding hall',
  'ceremony-plan.groom-house.time': '10:00 am',
  'ceremony-plan.bridge-house.time': '2:00 pm',
  'ceremony-plan.church.time': '3:00 pm',
  'ceremony-plan.wedding-hall.time': '4:30 pm',
  'ceremony-plan.groom-house-guests': 'Best man, the groom\'s parents',
  'ceremony-plan.bridge-house-guests': 'Godparents, best man, matron of honor, parents',
  'checkout.yes': 'Yes',
  'checkout.no': 'No',
  'checkout-step.presence': 'Presence',
  'checkout-step.accompanying-person': 'Accompanying person',
  'checkout-step.accommodation': 'Accommodation',
  'checkout-step.bus': 'Bus',
  'checkout-step.contact': 'Contact',
  'checkout-step.verification': 'Verification',
  'checkout-step.done': 'Done',
  'form-nav-buttons.next': 'Next',
  'form-nav-buttons.prev': 'Return',
  'form-nav-buttons.send': 'Send',
  'checkout-form-nav-buttons.edit': 'Edit',
  'checkout-form-item.choose-option': 'Please choose one of the options',
  'checkout-form-item.input-phone-number':
    'Please enter a valid phone number',
  'checkout-form-item.input-email': 'Please enter a valid e-mail',
  'checkout-presence-confirmation-form-item.present': 'I will come',
  'checkout-presence-confirmation-form-item.absent': 'I won\'t be',
  'checkout-presence-confirmation-form-item.dont-know': 'I don\'t know yet',
  'checkout-presence-confirmation-form-item.accompanying-person':
    'Accompanying person',
  'checkout-presence-confirmation-form-item.presence': 'Presence',
  'checkout-accommodation-form.text-1':
    'Dear guests! Due to the limited number of hotel rooms, We have to limit the possibility of declaring accommodation for guests from outside the nearest region. Sorry for the inconvenience.',
  'checkout-accommodation-form.text-2':
    'If you do not see your name in the part below, it means that you cannot declare your will to book a room. Please contact with <a href="https://dwastawy.com/" target="_blank" rel="noreferrer">wedding hall</a> or nearby hotel <a href="https://hotelcoliber.pl/" target="_blank" rel="noreferrer">"Coliber"</a> if you are interested in booking a room. We will inform you later about the possibility of a bus transfer from the hotel to the wedding hall.',
  'checkout-accommodation-form-item.accommodation': 'Hotel room',
  'checkout-bus-form.text-1':
    'Dear guests! The form you see is used to estimate the number of people interested in using the bus. The exact route of the bus will be given later depending on the interest in the given stop.',
  'checkout-bus-form.placeholder.choose-bus-stop': 'Choose your bus stop',
  'checkout-bus-form.placeholder.church-to-wedding-hall': "Bus from church to wedding hall",
  'checkout-bus-form.placeholder.return': "Return bus",
  'checkout-bus-form.church': 'Church',
  'checkout-bus-form.wedding-hall': 'Wedding hall',
  'checkout-contact-form.text-1':
    'We are approaching the end 🙂. Please fill out the last form. You can also ask questions here and let us know anything you want!',
  'checkout-contact-form.phone-number': 'Phone Number',
  'checkout-contact-form.email': 'E-mail',
  'checkout-contact-form.additional-info': 'Additional information',
  'checkout-contact-form.additional-info.placeholder':
    'Do you want to share something? Do you have any request?',
  'checkout-summary.text':
    'Dear guests! Thank you for completing the forms. Below you will find a summary of your choices, and in the next section you will choose a gift.',
  'checkout-summary.guest-dont-know': 'Attention! Leaving the "I don\'t know" option after the registration deadline will be equivalent to non-participation.',
  'checkout-summary.guest': 'Guest',
  'checkout-summary.presence': 'Presence',
  'checkout-summary.accompanying-person': 'Accompanying person',
  'checkout-summary.accommodation': 'Accommodation',
  'checkout-summary.bus': 'Bus',
  'checkout-summary.church': 'Church',
  'checkout-summary.wedding-hall': 'Wedding hall',
  'checkout-summary.list-frozen': 'The list has been closed and you are no longer able to change your selections. Please contact us if something is not correct.',
  'contact-form.text': 'Do you have any questions? Do you want to know more? Write to us!',
  'contact-form-item.phone-number': 'Phone number',
  'contact-form-item.email': 'E-mail',
  'contact-form-item.message': 'Message',
  'contact-form-item.message.placeholder': 'Your message :)',
  'gift-picker-choose-step.label': 'Please choose gifts from the list',
  'gift-picker-choose-step.choose-option': 'Please select at least one of the options',
  'gift-picker-choose-step.press-or-type-gift-name': 'Tap or type the gift name',
  'gift-step.choose': 'Choose the gift',
  'gift-step.done': 'Done',
  'gifts-summary.text': 'Thank you! Below you can see the gift you have chosen.',
  'gifts-summary.present': 'Selected gifts:',
  'localization.no': 'I DON\'T NEED',
  'localization.myszkow.groom-house': 'Myszków, The groom\'s house',
  'localization.myszkow.center': 'Myszków, Central Station',
  'localization.myszkow.mrzyglod': 'Myszków, Mrzygłód',
  'localization.myszkow.ciszowka': 'Myszków, Ciszówka',
  'localization.zarki': 'Żarki',
  'localization.mirow': 'Mirów',
  'localization.gorzkow-stary': 'Gorzków Stary',
  'localization.zloty-potok': 'Złoty Potok',
  'localization.czestochowa.jagiellonczycy': 'Częstochowa, Jagiellończycy Mall',
  'localization.czestochowa.kiedrzyn.parkitka':
    'Częstochowa, Kiedrzyn / Parkitka',
  'localization.czestochowa.central-railway-station':
    'Częstochowa, Central Station',
  'localization.czestochowa.stradom-railway-station':
    'Częstochowa, Stradom Station',
  'localization.huta-stara-a': 'Huta Stara A',
  'localization.huta-stara-b': 'Huta Stara B',
  'localization.poczesna.center': 'Poczesna, Town Hall',
  'localization.poczesna.church': 'Poczesna, Church',
  'localization.zawodzie.bridge-house': 'Zawodzie, The bride\'s house',
  'localization.klobuck.coliber': '"Coliber" hotel',
  'localization.lobodno': 'Sala weselna "Dwa Stawy & Złote Piaski" wedding hall',
  'localization.blachownia.center': 'Blachownia, Town Hall',
  'localization.blachownia.central-railway-station': 'Blachownia, Railway station',
  'localization.zawisna': 'Zawisna',
  'localization.zawada': 'Zawada',
  'localization.rudnik-wielki': 'Rudnik Wielki',
  'important-notes.accommodation': 'Accommodation',
  'important-notes.accommodation.text1': 'Guests interested in a hotel room are kindly requested to indicate this when confirming their presence. The accommodation is valid for a hotel day from September 3 to 4.',
  'important-notes.accommodation.text2': 'Due to the limited number of hotel rooms, We have to limit the possibility of declaring accommodation for guests from outside the nearest region. Interested persons are asked to contact the wedding hall or the hotel directly.',
  'important-notes.accommodation.text3': 'Guests who would like to extend their stay are also asked to contact the wedding hall directly.',
  'important-notes.accommodation.hotel': '"Coliber" Hotel',
  'important-notes.transport': 'Transport',
  'important-notes.transport.text1': 'Wedding guests who would like to use the bus are asked to mark it when confirming attendance.',
  'important-notes.transport.text2': 'Depending on the interest, a bus route will be developed, which we will publish on the website closer to the date of the ceremony.',
  'important-notes.gifts': 'Gifts',
  'important-notes.gifts.text1': 'Dear guests! We will be very pleased if you give us books and board games instead of alcohol and flowers. Below you will find a form that will allow you to choose a gift so that it does not repeat itself.',
  'best-man-and-woman.text': 'Here is our matron of honor and our best man. We thank them very much for their help. Don\'t be afraid to ask them anything if you need.',
  'errors.sign-in': 'Incorrect password. Please enter the password from the invitation.',
  'errors.unauthorized': 'User has not been logged in correctly! Please log in again.',
  'errors.undefined': 'Unknown error, please try again later or contact Ola or Sebastian.',
  'errors.try-later': 'Error while sending. Please try later.',
  'gifts.books': 'Books',
  'gifts.board-games': 'Board games',
  'albums.engagement_album': 'Engagement photo session',
  'albums.wedding_reportage_album': 'Wedding reportage',
  'albums.wedding_outdoor_album': 'Outdoor photo session',
  'albums.wedding_photo_booth_album': 'Photo booth session',
  'group.ceremony': 'Wedding ceremony',
  'group.parents': 'Words of thanks to Parents',
  'group.party': 'Let\'s party!',
  'group.family_performances': 'Family performances',
  'audio.title': 'Soundtrack',
  'movies.wedding_music_video': 'Music video',
  'movies.wedding_video': 'Full-length film',
  'movies.wedding_church_michal_love_anthem_video': 'Best man - Hymn o miłości',
  'movies.wedding_church_kasia_psalm_video': 'Bridesmaid - Psalm',
  'movies.wedding_church_ola_unosze_wzrok_video': 'Ola - Unoszę wzrok',
  'movies.wedding_ola_for_sebastian_video': 'Ola - Can\'t Help Falling in Love',
  'movies.wedding_kasia_byc_dla_kogos_video': 'Bridesmaid - Być dla kogoś',
  'movies.wedding_ola_mam_cudownych_rodzicow_video': 'Ola - Mam cudownych rodziców',
  'movies.wedding_parents_gift_video': 'Memories movie for Parents',
  'movies.wedding_kasia_bella_ciao_video': 'Bridesmaid - Bella Ciao',
  'movies.wedding_kasia_ola_wesele_video': 'Ola & Kasia - Wesele',
  'movies.wedding_ania_kasia_ola_jestem_brzydka_video': 'Ola, Ania, Kasia - Jestem brzydka',
  'movies.wedding_teresa_ania_tango_bajka_video': 'Grandma Teresa with Family - Tango "Bajka"',
  'movies.wedding_teresa_ania_serce_nie_sluga_video': 'Grandma Teresa with Family - Serce nie sługa',
  'service-providers.wedding_hall': 'Dwa Stawy & Złote Piaski Wedding Hall',
  'service-providers.band': 'Białe Kruki Band',
  'service-providers.bartender': 'Dave_bar Bartender',
  'service-providers.photographer': 'Tomasz Cichoń Wedding Photography',
  'service-providers.video': 'Paweł Bielecki Filming',
  'service-providers.photo_booth': 'K&M Notofoto - Photo booth',
  'thank-you': 'Thank you! ♥️',
  'guest-data.part_1': 'Do you want to see photos and videos taken by other guests?',
  'guest-data.part_2': 'Do you have any shots you\'d like to share?',
  'guest-data.part_3': 'We invite you to view the gallery created by guests and upload photos and videos taken by you!',
  'guest-data.button': 'Guest gallery'
}
