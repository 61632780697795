import {Col, Row} from 'antd'
import React from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import './AlbumSection.less'
import {Container} from "../Container/Container";
import {AlbumContainer} from "../AlbumContainer/AlbumContainer";
import {Album} from "../../types";
import {Spotify} from "react-spotify-embed";

export type AlbumSectionProps = React.HTMLAttributes<HTMLDivElement> & {
  albums: Album[]
}

export const AlbumSection = (props: AlbumSectionProps) => {
  const { t } = useTranslation()
  const { albums } = props

  let albumGroups = []

  let i = 1;
  while(i < albums.length - 1) {
    if (i % 2 == 0) {
      albumGroups.push([albums[i], albums[i+1]]);
      i += 2;
    }
    else {
      albumGroups.push([albums[i]]);
      i += 1;
    }
  }

  if(i < albums.length - 1) {
    albumGroups.push([albums[albums.length - 1]])
  }

  return (
    <Container>
      <Col span={20} flex={1}>
        <Row>
          <Col span={12}>
            <div className="spotify">
              <h4>{t('audio.title')}</h4>
              <Spotify link="https://open.spotify.com/playlist/2WJpF3EkGIwswt8TrvmJUk?si=3c2b4664d47a471f" />
            </div>
          </Col>
          <Col span={12}>
            {albums.length > 0 &&
              <AlbumContainer
                album={albums[0]}
              />
            }
          </Col>
        </Row>
        {albumGroups.map((group, index) => (
         <Row key={index}>
           {group.map((item) => (
             <Col key={item.id} span={24 / group.length}>
               <AlbumContainer
                 album={item}
               />
             </Col>
           ))}
         </Row>
        ))}
      </Col>
    </Container>
  )
}
