import React, { useEffect } from 'react'
import { Layout, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks/use-store'

import '../../translations/i18n'

import { Gallery } from '../../components/Gallery/Gallery'
import { NavBar } from '../NavBar/NavBar'

import Logo from '../../assets/logo.png'
import './PageLayout.less'
import { CustomDivider } from '../CustomDivider/CustomDivider'
import { CustomFooter } from '../CustomFooter/CustomFooter'
import { Welcome } from '../../components/Welcome/Welcome'
import { RestrictedPageLayout } from '../RestrictedPageLayout/RestrictedPageLayout'
import { Container } from '../../components/Container/Container'
import { SignInCard } from "../../components/SignInCard/SignInCard";
import {useViewport} from "../../hooks/use-viewport";


const { Header, Content } = Layout

type PageLayoutProps = React.HTMLAttributes<HTMLDivElement> & {
  language?: string
  setLanguage: React.Dispatch<React.SetStateAction<string>>
  showSignInModal: () => void
  hideSignInModal: () => void
  isSignInModalVisible: boolean
}

export const PageLayout = (props: PageLayoutProps) => {
  const {
    language,
    setLanguage,
    showSignInModal,
    hideSignInModal,
  } = props
  const { t } = useTranslation()

  const { fetchUser, fetchMultimedia, store } = useStore()
  const { galleryVerticalView } = useViewport()

  useEffect(() => {
    const fetchData = async () => {
      await fetchUser()
    }

    if(store.session) {
      try{
        fetchData()
      }
      catch (error) {
        console.error("Cannot fetch user and available gifts")
      }
    }
  }, [])

  useEffect(() => {
    if(store.session && store.session.multimedia === undefined) {
      try{
        fetchMultimedia()
      }
      catch (error) {
        console.error("Cannot fetch user and available gifts")
      }
    }
  }, [store.session])

  const session = store.session

  return (
    <Layout>
      <Header className="header">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <NavBar
          language={language}
          setLanguage={setLanguage}
          showSignInModal={showSignInModal}
          hideSignInModal={hideSignInModal}
        />
      </Header>
      <Content className="site-layout">
        { galleryVerticalView ?
          <>
            <Container className="gallery-container">
              <Col>
                <Gallery />
              </Col>
            </Container>
            <Container>
              <Col>
                <Welcome />
              </Col>
            </Container>
          </> :
          <Container className="gallery-container" justify="space-around">
            <Col className="gallery-container-col" flex="none">
              <Gallery />
            </Col>
            <Col className="welcome-container-col" flex="none">
              <Welcome />
            </Col>
          </Container>
        }

        { session?.userInfo && <RestrictedPageLayout session={session} user={session?.userInfo} /> }
      </Content>

      <CustomFooter/>
    </Layout>
  )
}
