import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  createContext,
} from 'react'

const viewportContext = createContext({ width: 2000, height: 1000 })

type ViewportProviderProps = React.HTMLAttributes<HTMLDivElement>

export const ViewportProvider = (props: ViewportProviderProps) => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [height, setHeight] = useState<number>(window.innerHeight)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <viewportContext.Provider value={{ width, height }}>
      {props.children}
    </viewportContext.Provider>
  )
}

export const useViewport = () => {
  const { width, height } = useContext(viewportContext)

  const isMobileView = useMemo(() => {
    return width < 750
  }, [width])

  const verticalView = useMemo(() => {
    return width < height
  }, [width, height])

  const galleryVerticalView = useMemo(() => {
    // min log-in = 400px, header = 80px
    // photo: 4 x 3
    // 4p + 400 < w
    // 3p + 80 = h
    return 3 * width - 4 * height < 880
  }, [width, height])

  return { width, height, isMobileView, verticalView, galleryVerticalView }
}
