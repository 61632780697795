import {useMemo, useState} from 'react'
import { Form, message } from 'antd'

import { useTranslation } from 'react-i18next'
import '../../../translations/i18n'
import { SignInError } from "../../../errors/SignInError";

type UseSignInProps = {
  signIn: (password: string, email?: string) => void
  hideModal: () => void
}

export const useSignIn = (props: UseSignInProps) => {
  const { signIn, hideModal } = props
  const [passwordValid, setPasswordValid] = useState<boolean>(true)
  const [submitButtonDisabled] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const { t } = useTranslation()

  const cleanToken = () => {
    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.has('token')) {
      queryParams.delete('token')
      window.history.replaceState({}, document.title, "/")
    }
  }

  const initialValue = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search)

    return queryParams.has('token') ? (queryParams.get('token') || '') : ''
  }, [])

  const handleCancel = () => {
    cleanToken()
    hideModal()
  }

  const handleSubmitClick = () => {
    form.submit()
  }

  const handleSubmitPassed = async (values: any) => {
    setConfirmLoading(true)
    const passwordFromToken = initialValue
    const passwordFromInput = values.password
    let password = passwordFromInput

    if(passwordFromToken) {
      password = passwordFromToken + passwordFromInput
    } else {
      password = passwordFromInput
    }

    try {
      await signIn(password)
      setConfirmLoading(false)
      form.resetFields()
      hideModal()
      cleanToken()

      message.success({
        content: t('sign-in.success'),
        style: {
          marginTop: '80px',
        },
      })
    }
    catch (error) {
      let content = (error as Error).message
      if (error instanceof SignInError) {
        setPasswordValid(false)
        form.validateFields()
      }

      message.error({
        content: t(content),
        style: {
          marginTop: '80px',
        },
      })
    }
    finally {
      setConfirmLoading(false)
    }
  }

  const handleSubmitFailed = () => {}

  const handleValueChange = (changedValues: any) => {
    if (changedValues.password) {
      setPasswordValid(true)
    }
  }

  const signInWithQR = async () => {
    const passwordFromToken = initialValue

    if(passwordFromToken.length === 12) {
      await handleSubmitPassed({ password: passwordFromToken })
    }
  }

  return {
    handleCancel,
    handleSubmitClick,
    handleSubmitPassed,
    handleSubmitFailed,
    handleValueChange,
    confirmLoading,
    passwordValid,
    setPasswordValid,
    submitButtonDisabled,
    form,
    initialValue,
    signInWithQR
  }
}
