import { ReactSession } from "react-client-session";
import { UserInfo, Multimedia } from "../types";
import { SessionInfo } from "./sessionInfo";

export class Session {
  public sessionInfo: SessionInfo
  public userInfo: UserInfo | undefined
  public multimedia: Multimedia | undefined

  constructor(sessionInfo: SessionInfo, userInfo?: UserInfo, multimedia?: Multimedia) {
    this.sessionInfo = sessionInfo
    this.userInfo = userInfo
    this.multimedia = multimedia

    ReactSession.set('session', this)
  }

  public userFetched = () => {
    return this.userInfo !== undefined
  }

  public setSessionInfo = (sessionInfo: SessionInfo) => {
    this.sessionInfo = sessionInfo

    ReactSession.set('session', this)
  }

  public setUserInfo = (userInfo: UserInfo | undefined) => {
    this.userInfo = userInfo

    ReactSession.set('session', this)
  }

  public setMultimedia = (multimedia: Multimedia | undefined) => {
    this.multimedia = multimedia

    ReactSession.set('session', this)
  }
}
