import {Col, Row} from 'antd'
import React, {useEffect} from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import './MovieSection.less'
import {Container} from "../Container/Container";
import {MovieContainer} from "../MovieContainer/MovieContainer";
import {Movie} from "../../types";

export type MovieSectionProps = React.HTMLAttributes<HTMLDivElement> & {
  movies: Movie[],
  elementsInRow: number,
  grouping?: boolean
}

export const MovieSection = (props: MovieSectionProps) => {
  const { t } = useTranslation()
  const { movies, elementsInRow, grouping } = props

  let moviesGrouped: Array<{ group: string, items: Movie[], rows?: Array<Movie[]>}> = []
  let groups: {[key: string | number]: Movie[]} = {}

  // Grouping
  if (grouping) {
    movies.forEach((movie) => {
      if (!groups[movie.group || '']) {
        groups[movie.group || ''] = [];
      }

      groups[movie.group || ''].push(movie)
    });

    for (const key in groups) {
      moviesGrouped.push({
        group: key,
        items: groups[key],
      })
    }
  }
  else {
    moviesGrouped.push({
      group: '',
      items: movies,
    })
  }

  // Rows
  moviesGrouped.forEach((group) => {
    const { items } = group
    const rowsCount = Math.ceil(items.length / elementsInRow)
    const rows = []

    for (let i = 0; i < rowsCount; i++) {
      const row = []

      for (let j = 0; j < elementsInRow; j++) {
        if (items[i * elementsInRow + j]) {
          row.push(items[i * elementsInRow + j])
        }
      }

      rows.push(row)
    }

    group.rows = rows
  });

  return (
    <Container>
      <Col span={20} flex={1}>
        {moviesGrouped.map((moviesGroup) => (
          <div className='movie-section-group' key={moviesGroup.group}>
          {grouping ? <h2>{t(`group.${moviesGroup.group}`)}</h2>: null }
          {(moviesGroup.rows || []).map((row, index) => (
            <Row key={index}>
              {row.map((movie) => (
                <Col key={movie.id} span={24 / elementsInRow}>
                  <MovieContainer
                    key={movie.id}
                    movie={movie}
                  />
                </Col>
              ))}
            </Row>
          ))}
          </div>
        ))}
      </Col>
    </Container>
  )
}
