import {Col, Row } from 'antd'
import React from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import './ServiceProvidersSection.less'
import { Container } from "../Container/Container";
import { serviceProviders } from "../../data";
import { ServiceProviderContainer } from "../ServiceProviderContainer/ServiceProviderContainer";

export type ServiceProvidersSectionProps = React.HTMLAttributes<HTMLDivElement> & {}

export const ServiceProvidersSection = (props: ServiceProvidersSectionProps) => {
  const { t } = useTranslation()

  let rows = [];
  for (let i = 0; i < Math.ceil(serviceProviders.length/3); i++) {
    let row = [];
    for (let j = 0; j < 3; j++) {
      if (3 * i + j < serviceProviders.length) {
        row.push(serviceProviders[3*i+j]);
      }
    }

    rows.push(row);
  }

  return (
    <Container>
      <Col span={20} flex={1}>
        {rows.map((row, index) => (
          <Row key={index} justify="space-between">
            {row.map((item) => (
              <Col key={item.id} span={7}>
                <ServiceProviderContainer key={item.id} serviceProvider={item} />
              </Col>
            ))}
          </Row>
        ))}
      </Col>
    </Container>
  )
}
