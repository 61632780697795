import React from 'react'

import { Row, Col } from 'antd'

import EucalyptusLeft from '../../assets/eucalyptus_left.png'
import EucalyptusRight from '../../assets/eucalyptus_right.png'

import './CustomDivider.less'

export type CustomDividerProps = React.HTMLAttributes<HTMLDivElement> & {
  id?: string
}

export const CustomDivider = (props: CustomDividerProps) => {
  return (
    <Row id={props.id} className="divider">
      <Col span={5} offset={2}>
        <img src={EucalyptusLeft} alt="eucalyptus" />
      </Col>
      <Col span={8} offset={1} flex={1}>
        <div className="divider-text">{props.children}</div>
      </Col>
      <Col span={5} offset={1}>
        <img src={EucalyptusRight} alt="eucalyptus" />
      </Col>
    </Row>
  )
}
