import React, {PropsWithChildren} from 'react'
import { Spotify } from 'react-spotify-embed'
import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import { UserInfo } from '../../types'
import { Session } from "../../api/session";

import { CustomDivider } from '../CustomDivider/CustomDivider'
import {MovieSection} from "../../components/MovieSection/MovieSection";
import {AlbumSection} from "../../components/AlbumSection/AlbumSection";
import {GuestDataSection} from "../../components/GuestDataSection/GuestDataSection";
import {ServiceProvidersSection} from "../../components/ServiceProvidersSection/ServiceProvidersSection";
import {serviceProviders} from "../../data";

type RestrictedPageLayoutProps = {
  session: Session
  user: UserInfo
}

export const RestrictedPageLayout = (props: PropsWithChildren<RestrictedPageLayoutProps>) => {
  const { session, user } = props
  const { t } = useTranslation()

  return (
    session.multimedia ?
        <>
          <CustomDivider id="movies">
            <h1>{t('divider.movies')}</h1>
          </CustomDivider>
          <MovieSection elementsInRow={1} grouping={false} movies={session.multimedia.movies}/>

          <CustomDivider id="movie-fragments">
            <h1>{t('divider.movie-fragments')}</h1>
          </CustomDivider>
          <MovieSection elementsInRow={3} grouping={true} movies={session.multimedia.fragments}/>

          <CustomDivider id="photos">
            <h1>{t('divider.photos')}</h1>
          </CustomDivider>
          <AlbumSection albums={session.multimedia.albums}/>

          <CustomDivider id="share">
            <h1>{t('divider.add-your-files')}</h1>
          </CustomDivider>
          <GuestDataSection galleryURL={session.multimedia.guestGallery} />

          <CustomDivider id="service-providers">
            <h1>{t('divider.service-providers')}</h1>
          </CustomDivider>
          <ServiceProvidersSection/>

          <h1>{t('thank-you')}</h1>
        </>
        : <></>
  )
}
