export const PL = {
  'navbar.menu': 'Menu',
  'navbar.item.invitation': 'Zaproszenie',
  'navbar.item.ceremony-plan': 'Plan ceremonii',
  'navbar.item.confirm-presence': 'Potwierdź obecność',
  'navbar.item.choose-present': 'Wybierz prezent',
  'navbar.item.contact': 'Kontakt',
  'sign-in.text': 'Zaloguj się',
  'sign-in.button': 'Zaloguj się',
  'sign-in.welcome':
    'Dzień dobry! Bardzo nam miło, że chcesz się zalogować. Pozwoli Ci to na dokładniejsze poznanie harmonogramu naszego wyjątkowego dnia, wybór prezentu czy potwierdzenie obecności.',
  'sign-in.password-location':
    'Hasło potrzebne do zalogowania się znajdziesz w dolnej części zaproszenia.',
  'sign-in-form.password': 'Hasło',
  'sign-in-form.password.second-part': 'Hasło (3 znaki z zielonej ramki)',
  'sign-in-form.validation.too-short-password':
    'Za krótkie hasło. Proszę wprowadzić hasło z zaproszenia',
  'sign-in-form.validation.invalid-password':
    'Niepoprawne hasło. Proszę wprowadzić hasło z zaproszenia',
  'sign-in-modal.header': 'Logowanie',
  'sign-in-modal.close': 'Zamknij',
  'sign-in-modal.sign-in': 'Zaloguj się',
  'sign-in.success': 'Pomyślnie zalogowałeś się',
  'form.done': 'Poszło! Dziękujemy!',
  'user-menu.sign-out': 'Wyloguj się',
  'user-menu.change-password': 'Zmień hasło',
  'divider.see-once-again': 'Przeżyj to jeszcze raz!',
  'divider.movies': 'Światła, kamera, akcja!',
  'divider.movie-fragments': 'Fragmenty artystyczne',
  'divider.photos': 'Uwiecznione chwile',
  'divider.add-your-files': 'Wasze zdjęcia i filmy',
  'divider.service-providers': 'Nasi usługodawcy',
  'divider.year-passed': 'To już rok!',
  'divider.thanks': 'Dziękujemy!',
  'divider.best-man-and-woman': 'Nasi Świadkowie',
  'divider.dear-guests': 'Drodzy goście',
  'divider.ceremony-plan': 'Plan ceremonii',
  'divider.confirm-presence': 'Potwierdź obecność',
  'divider.choose-present': 'Wybierz prezent',
  'divider.contact': 'Kontakt',
  'divider.route': 'Jak dotrzeć?',
  'divider.region': 'Witaj na Jurze!',
  'divider.important-notes': 'Ważne informacje',
  'time.day': 'dzień',
  'time.days': 'dni',
  'event.add-to-calendar': 'Dodaj do kalendarza',
  'event.do-not-want-to-miss': 'Nie chcesz przegapić?',
  'event.left': 'POZOSTAŁO',
  'event.confirm-presence.title': 'POTWIERDŹ OBECNOŚĆ',
  'event.confirm-presence.name':
    'Potwierdź obecność - uroczystość Oli & Sebastiana',
  'event.confirm-presence.details':
    'Potwierdź obecność, wszelkie informacje znajdziesz na stronie internetowej: ',
  'event.wedding.title': 'DATA UROCZYSTOŚCI',
  'event.wedding.name':
    'Uroczystość zawarcia związku małżeńskiego Oli & Sebastiana',
  'event.wedding.details':
    'Wszelkie informacje znajdziesz na stronie internetowej: ',
  'event.wedding.location':
    'Kościół Rzymskokatolicki pw. Św. Jana Chrzciciela, Strażacka 9, 42-262 Poczesna, Polska',
  'welcome.text':
    'Kochani! Dziękujemy Wam za uczestniczenie w jednym z najważniejszych wydarzeń w naszym życiu. Po zalogowaniu się będziecie mogli zobaczyć wszystkie materiały i przeżyć te chwile jeszcze raz. Zapraszamy!',
  'ceremony-plan-item.address': 'Adres',
  'ceremony-plan-item.people': 'Zapraszamy',
  'ceremony-plan.groom-house': 'Dom Pana Młodego',
  'ceremony-plan.bridge-house': 'Dom Pani Młodej',
  'ceremony-plan.church': 'Kościół Rzymskokatolicki pw. Św. Jana Chrzciciela',
  'ceremony-plan.wedding-hall': 'Sala przyjęć okolicznościowych "Złote Piaski"',
  'ceremony-plan.groom-house.time': 'godzina 10:00',
  'ceremony-plan.bridge-house.time': 'godzina 14:00',
  'ceremony-plan.church.time': 'godzina 15:00',
  'ceremony-plan.wedding-hall.time': 'godzina 16:30',
  'ceremony-plan.groom-house-guests': 'Świadek Pana Młodego, Rodzice Pana Młodego',
  'ceremony-plan.bridge-house-guests': 'Chrzestni, Świadkowie, Rodzice',
  'checkout.yes': 'Tak',
  'checkout.no': 'Nie',
  'checkout-step.presence': 'Obecność',
  'checkout-step.accompanying-person': 'Partner',
  'checkout-step.accommodation': 'Nocleg',
  'checkout-step.bus': 'Bus',
  'checkout-step.contact': 'Kontakt',
  'checkout-step.verification': 'Weryfikacja',
  'checkout-step.done': 'Koniec',
  'form-nav-buttons.next': 'Dalej',
  'form-nav-buttons.prev': 'Wstecz',
  'form-nav-buttons.send': 'Wyślij',
  'checkout-form-nav-buttons.edit': 'Edytuj',
  'checkout-form-item.choose-option': 'Proszę wybierz jedną z opcji',
  'checkout-form-item.input-phone-number':
    'Proszę wpisz poprawny numer telefonu',
  'checkout-form-item.input-email': 'Proszę wpisz poprawny adres e-mail',
  'checkout-presence-confirmation-form-item.present': 'Będę',
  'checkout-presence-confirmation-form-item.absent': 'Nie będę',
  'checkout-presence-confirmation-form-item.dont-know': 'Nie wiem',
  'checkout-presence-confirmation-form-item.accompanying-person':
    'Osoba towarzysząca',
  'checkout-presence-confirmation-form-item.presence': 'Obecność',
  'checkout-accommodation-form.text-1':
    'Kochani! Z powodu ograniczonej liczby miejsc w części hotelowej sali weselnej, Państwo Młodzi musieli ograniczyć możliwość deklaracji noclegu dla gości spoza północnej części województwa śląskiego.',
  'checkout-accommodation-form.text-2':
    'Jeżeli nie widzisz w poniższej części twojego imienia i nazwiska oznacza to, że nie masz możliwości deklaracji chęci skorzystania z noclegu. Takie osoby, które jednak chciałyby skorzystać z noclegu, proszone są o bezpośredni kontakt z <a href="https://dwastawy.com/" target="_blank" rel="noreferrer">salą weselną</a> lub polecanym przez Nas hotelem <a href="https://hotelcoliber.pl/" target="_blank" rel="noreferrer">"Coliber"</a> znajdującym się w Kłobucku, około 7 min drogi samochodem od sali weselnej. W przypadku dużego zainteresowania, będzie możliwość przejazdu do hotelu za pomocą busika.',
  'checkout-accommodation-form-item.accommodation': 'Nocleg',
  'checkout-bus-form.text-1':
    'Drodzy goście! Formularz, który widzicie poniżej ma na celu oszacowanie zainteresowania przyjazdu na Naszą uroczystość busikiem. W zależności od niego, zostanie dobrana jak najbardziej optymalna trasa, uwzględniając nawet zabranie poszczególnych gości spod ich domów.',
  'checkout-bus-form.placeholder.choose-bus-stop': 'Wybierz przystanek',
  'checkout-bus-form.placeholder.church-to-wedding-hall': "Bus Kościół -> Sala",
  'checkout-bus-form.placeholder.return': "Powrót z wesela",
  'checkout-bus-form.church': 'Kościół',
  'checkout-bus-form.wedding-hall': 'Sala weselna',
  'checkout-contact-form.text-1':
    'Kochani! Niestety nie posiadamy numerów telefonu do wszystkich naszych Gości. W celu ułatwienia kontaktu z Wami, a także umożliwienia szybszego przekazania informacji o ewentualnych zmianach chcielibyśmy, abyście wypełnili poniższy formularz. Możecie również zadać pytania i poinformować Nas o czymkolwiek chcecie!',
  'checkout-contact-form.phone-number': 'Nr telefonu',
  'checkout-contact-form.email': 'E-mail',
  'checkout-contact-form.additional-info': 'Dodatkowe informacje',
  'checkout-contact-form.additional-info.placeholder':
    'Chcesz się czymś podzielić? Masz jakąś prośbę?',
  'checkout-summary.text':
    'Drodzy goście! Dziękujemy za wypełnienie formularza. Poniżej znajdziecie podsumowanie Waszych wyborów, a w następnej sekcji wybierzecie prezent.',
  'checkout-summary.guest-dont-know': 'Uwaga! Pozostawienie wyboru "Nie wiem" po upływie terminu deklaracji będzie jednoznaczne z brakiem uczestnictwa.',
  'checkout-summary.guest': 'Gość',
  'checkout-summary.presence': 'Obecność',
  'checkout-summary.accompanying-person': 'Osoba towarzysząca',
  'checkout-summary.accommodation': 'Nocleg',
  'checkout-summary.bus': 'Bus',
  'checkout-summary.church': 'Kościół',
  'checkout-summary.wedding-hall': 'Sala weselna',
  'checkout-summary.list-frozen': 'Lista została zamknięta. Nie macie możliwości dokonania zmian. Jeżeli widzicie jakiś błąd, prosimy o kontakt z Młodą Parą.',
  'contact-form.text': 'Macie jakieś pytania? Chcecie wiedzieć więcej? Napiszcie do Nas!',
  'contact-form-item.phone-number': 'Nr telefonu',
  'contact-form-item.email': 'Email',
  'contact-form-item.message': 'Wiadomość',
  'contact-form-item.message.placeholder': 'Twoja wiadomość :)',
  'gift-picker-choose-step.label': 'Wybierz prezenty z listy',
  'gift-picker-choose-step.choose-option': 'Proszę wybierz przynajmniej jedną z opcji',
  'gift-picker-choose-step.press-or-type-gift-name': 'Naciśnij lub wpisz nazwę prezentu',
  'gift-step.choose': 'Wybierz prezent',
  'gift-step.done': 'Koniec',
  'gifts-summary.text': 'Dziękujemy! Poniżej możecie zobaczyć prezent, które wybraliście dla Państwa Młodych.',
  'gifts-summary.present': 'Wybrane prezenty:',
  'localization.no': 'NIE SKORZYSTAM',
  'localization.myszkow.groom-house': 'Myszków, Dom Pana Młodego',
  'localization.myszkow.center': 'Myszków, Plac Dworcowy',
  'localization.myszkow.mrzyglod': 'Myszków, Mrzygłód',
  'localization.myszkow.ciszowka': 'Myszków, Ciszówka',
  'localization.zarki': 'Żarki',
  'localization.mirow': 'Mirów',
  'localization.gorzkow-stary': 'Gorzków Stary',
  'localization.zloty-potok': 'Złoty Potok',
  'localization.czestochowa.jagiellonczycy': 'Częstochowa, C.H. Jagiellończycy',
  'localization.czestochowa.kiedrzyn.parkitka':
    'Częstochowa, Kiedrzyn / Parkitka',
  'localization.czestochowa.central-railway-station':
    'Częstochowa, Dworzec Główny',
  'localization.czestochowa.stradom-railway-station':
    'Częstochowa, Dworzec Stradom',
  'localization.huta-stara-a': 'Huta Stara A',
  'localization.huta-stara-b': 'Huta Stara B',
  'localization.poczesna.center': 'Poczesna, Urząd Gminy',
  'localization.poczesna.church': 'Poczesna, Kościół',
  'localization.zawodzie.bridge-house': 'Zawodzie, Dom Panny Młodej',
  'localization.klobuck.coliber': 'Hotel "Coliber"',
  'localization.lobodno': 'Sala weselna "Dwa Stawy & Złote Piaski"',
  'localization.blachownia.center': 'Blachownia, Urząd Miasta',
  'localization.blachownia.central-railway-station': 'Blachownia, PKP',
  'localization.zawisna': 'Zawisna',
  'localization.zawada': 'Zawada',
  'localization.rudnik-wielki': 'Rudnik Wielki',
  'important-notes.accommodation': 'Nocleg',
  'important-notes.accommodation.text1': 'Goście zainteresowani noclegiem proszeni są o zaznaczenie tego podczas potwierdzania obecności. Nocleg obejmuje dobę hotelową z 3 - 4 września.',
  'important-notes.accommodation.text2': 'Z powodu ograniczonej liczby miejsc w części hotelowej sali weselnej, Państwo Młodzi musieli ograniczyć możliwość deklaracji noclegu dla gości spoza północnej części województwa śląskiego. Pozostałe, nieuwzględnione w deklaracji osoby chcące zarezerwować sobie nocleg, proszone są o bezpośredni kontakt z salą weselną lub hotelem.',
  'important-notes.accommodation.text3': 'Osoby, które chciałyby przedłużyć pobyt również proszone są o bezpośredni kontakt z salą weselną.',
  'important-notes.accommodation.hotel': 'Hotel Coliber',
  'important-notes.transport': 'Transport',
  'important-notes.transport.text1': 'Weselnicy, którzy chcieliby skorzystać z busa, proszeni są o zaznaczenie tego podczas potwierdzania obecności.',
  'important-notes.transport.text2': 'W zależności od zainteresowania zostanie opracowana trasa busika, którą opublikujemy na stronie bliżej terminu uroczystości.',
  'important-notes.gifts': 'Prezenty',
  'important-notes.gifts.text1': 'Kochani goście! Będzie nam bardzo miło, jeśli zamiast alkoholu i kwiatów obdarujecie Nas książkami i grami planszowymi. Poniżej znajdziecie formularz, który umożliwi Wam wybranie jakiegoś prezentu, aby się on nie powtórzył.',
  'best-man-and-woman.text': 'W organizacji naszego wyjątkowego dnia będą Nam pomagać niezwykle pomocni świadkowie, którzy już teraz zasługują na słowa uznania! W przypadku chęci lub konieczności uzyskania informacji bądź pomocy związanej z wydarzeniem, prosimy o kontakt z Nami bądź wskazanymi poniżej osobami 🙂.',
  'errors.sign-in': 'Niepoprawne hasło. Proszę wprowadzić hasło z zaproszenia',
  'errors.unauthorized': 'Użytkownik nie jest poprawnie zalogowany! Spróbuj się zalogować ponownie',
  'errors.undefined': 'Nieznany błąd, proszę spróbować później lub skontaktować się z Państwem Młodym',
  'errors.try-later': 'Ups! Błąd podczas wysyłania. Proszę spróbować później',
  'gifts.books': 'Książki',
  'gifts.board-games': 'Gry planszowe',
  'albums.engagement_album': 'Sesja narzeczeńska',
  'albums.wedding_reportage_album': 'Reportaż ślubny',
  'albums.wedding_outdoor_album': 'Sesja plenerowa',
  'albums.wedding_photo_booth_album': 'Fotobudka',
  'group.ceremony': 'Ślub',
  'group.parents': 'Podziękowania dla Rodziców',
  'group.party': 'Zabawa',
  'group.family_performances': 'Rodzinna biesiada',
  'audio.title': 'Ścieżka dźwiękowa z filmu - umil sobie oglądanie',
  'movies.wedding_music_video': 'Teledysk - na dobry początek',
  'movies.wedding_video': 'Film - na dłuższe posiedzenie',
  'movies.wedding_church_michal_love_anthem_video': 'Świadek - Hymn o miłości',
  'movies.wedding_church_kasia_psalm_video': 'Świadkowa - Psalm',
  'movies.wedding_church_ola_unosze_wzrok_video': 'Ola - Unoszę wzrok',
  'movies.wedding_ola_for_sebastian_video': 'Ola - Can\'t Help Falling in Love',
  'movies.wedding_kasia_byc_dla_kogos_video': 'Świadkowa - Być dla kogoś',
  'movies.wedding_ola_mam_cudownych_rodzicow_video': 'Ola - Mam cudownych rodziców',
  'movies.wedding_parents_gift_video': 'Film dla Rodziców',
  'movies.wedding_kasia_bella_ciao_video': 'Świadkowa - Bella Ciao',
  'movies.wedding_kasia_ola_wesele_video': 'Ola i Kasia - Wesele',
  'movies.wedding_ania_kasia_ola_jestem_brzydka_video': 'Ola, Ania, Kasia - Jestem brzydka',
  'movies.wedding_teresa_ania_tango_bajka_video': 'Babcia Teresa z Rodziną - Tango "Bajka"',
  'movies.wedding_teresa_ania_serce_nie_sluga_video': 'Babcia Teresa z Rodziną - Serce nie sługa',
  'service-providers.wedding_hall': 'Sala weselna Dwa Stawy & Złote Piaski',
  'service-providers.band': 'Zespół muzyczny Białe Kruki',
  'service-providers.bartender': 'Dave_bar usługi barmańskie',
  'service-providers.photographer': 'Fotografia ślubna Tomasz Cichoń',
  'service-providers.video': 'Paweł Bielecki Filmowanie',
  'service-providers.photo_booth': 'K&M Notofoto - Fotolustro',
  'thank-you': 'Dziękujemy! ♥️',
  'guest-data.part_1': 'Chcesz zobaczyć zdjęcia i filmy wykonane przez inne osoby?',
  'guest-data.part_2': 'Masz jakieś ujęcia, którymi chciałbyś się podzielić?',
  'guest-data.part_3': 'Serdecznie zapraszamy do obejrzenia Waszej galerii oraz wrzucenia zdjęć i filmów wykonanych przez Was!',
  'guest-data.button': 'Galeria gości'
}
