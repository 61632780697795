export const isDevelopment = process.env.NODE_ENV !== "production"

export const API_HOST = process.env.REACT_APP_API_HOST || 'http://localhost:3000'
export const BASE_API_V1_PATH = `${API_HOST}/api/v1/`

export enum Routes {
  signIn = "/auth/sign_in",
  signOut = "/auth/sign_out",
  me = "/users/me",
  update = "/users/",
  availableGifts = "/gifts/available",
  updateReservations = "/users/:id/reservations",
  contact = "/users/:id/contact"
}