import { Form, FormInstance, Input, Row, Col } from 'antd'
import React from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import './SignInForm.less'

type SignInFormProps = React.HTMLAttributes<HTMLDivElement> & {
  form: FormInstance
  handleSubmitPassed: (values: any) => void
  handleSubmitFailed: () => void
  handleValueChange: (chnagedValues: any) => void
  passwordValid: boolean
  initialValue?: string
}

export const SignInForm = (props: SignInFormProps) => {
  const { t } = useTranslation()

  const { form, handleSubmitPassed, handleSubmitFailed, handleValueChange, passwordValid, initialValue } = props

  return (
    <Form
      className='sign-in-form'
      form={form}
      name="sign-in"
      autoComplete="off"
      onFinish={handleSubmitPassed}
      onFinishFailed={handleSubmitFailed}
      onValuesChange={handleValueChange}
    >
      <Row justify="center" gutter={8}>
        <Form.Item
          className="sign-in-form-item"
          label={initialValue ? t('sign-in-form.password.second-part') : t('sign-in-form.password')}
          name="password"
          key="password"
          hasFeedback
          rules={[
            {
              validator: (_, value) => {
                if(initialValue) {
                  return Promise.resolve()
                }

                if (value.length > 4 && passwordValid) {
                  return Promise.resolve()
                }

                if (value.length <= 4) {
                  return Promise.reject(new Error(t('sign-in-form.validation.too-short-password')))
                }

                return Promise.reject(new Error(t('sign-in-form.validation.invalid-password')))
              }
            },
          ]}
        >
          <Col flex={1} span={24}>
            <Input.Password />
          </Col>
        </Form.Item>
      </Row>
    </Form>
  )
}
