import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from "../../hooks/use-store"
import '../../translations/i18n'

import {
  BookOutlined,
  ScheduleOutlined,
  CheckCircleOutlined,
  GiftOutlined,
  MenuOutlined,
  PhoneOutlined,
} from '@ant-design/icons'

import { Menu } from 'antd'

import { UserMenu } from '../../components/UserMenu/UserMenu'
import { LanguageSwitcher } from '../../components/LanguageSwitcher/LanguageSwitcher'

import { NavBarProps } from './NavBar'

import './NavBar.less'
import { NavBarCustomMenuItem } from '../../components/NavBarCustomMenuItem/NavBarCustomMenuItem'

export const NavBarMobile = (props: NavBarProps) => {
  const { language, setLanguage, showSignInModal } = props

  const { SubMenu } = Menu

  const { store } = useStore()
  const { t } = useTranslation()
  const handleSignInClick = () => {}
  const handleChangeLanguageClick = () => {}
  const menuMode = 'horizontal'
  const offset = -100
  const duration = 500
  const isUserLoggedIn = store.session !== undefined

  return (
    <Menu className="menu" mode={menuMode} selectable={false}>
      {/*<SubMenu*/}
      {/*  key="sub-menu"*/}
      {/*  icon={<MenuOutlined />}*/}
      {/*  title={t('navbar.menu')}*/}
      {/*  popupClassName={'submenu'}*/}
      {/*>*/}
      {/*  <NavBarCustomMenuItem*/}
      {/*    key="1"*/}
      {/*    icon={<BookOutlined />}*/}
      {/*    duration={duration}*/}
      {/*    offset={offset}*/}
      {/*    linkTarget="welcome"*/}
      {/*    content={t('navbar.item.invitation')}*/}
      {/*    isUserLoggedIn={true}*/}
      {/*    isActionRestricted={false}*/}
      {/*    showSignInModal={showSignInModal}*/}
      {/*  />*/}

      {/*  <NavBarCustomMenuItem*/}
      {/*    key="2"*/}
      {/*    icon={<ScheduleOutlined />}*/}
      {/*    duration={duration}*/}
      {/*    offset={offset}*/}
      {/*    linkTarget="ceremony-plan"*/}
      {/*    content={t('navbar.item.ceremony-plan')}*/}
      {/*    isUserLoggedIn={isUserLoggedIn}*/}
      {/*    isActionRestricted={true}*/}
      {/*    showSignInModal={showSignInModal}*/}
      {/*  />*/}

      {/*  <NavBarCustomMenuItem*/}
      {/*    key="3"*/}
      {/*    icon={<CheckCircleOutlined />}*/}
      {/*    duration={duration}*/}
      {/*    offset={offset}*/}
      {/*    linkTarget="presence"*/}
      {/*    content={t('navbar.item.confirm-presence')}*/}
      {/*    isUserLoggedIn={isUserLoggedIn}*/}
      {/*    isActionRestricted={true}*/}
      {/*    showSignInModal={showSignInModal}*/}
      {/*  />*/}

      {/*  <NavBarCustomMenuItem*/}
      {/*    key="4"*/}
      {/*    icon={<GiftOutlined />}*/}
      {/*    duration={duration}*/}
      {/*    offset={offset}*/}
      {/*    linkTarget="present"*/}
      {/*    content={t('navbar.item.choose-present')}*/}
      {/*    isUserLoggedIn={isUserLoggedIn}*/}
      {/*    isActionRestricted={true}*/}
      {/*    showSignInModal={showSignInModal}*/}
      {/*  />*/}

      {/*  <NavBarCustomMenuItem*/}
      {/*    key="5"*/}
      {/*    icon={<PhoneOutlined />}*/}
      {/*    duration={duration}*/}
      {/*    offset={offset}*/}
      {/*    linkTarget="contact"*/}
      {/*    content={t('navbar.item.contact')}*/}
      {/*    isUserLoggedIn={isUserLoggedIn}*/}
      {/*    isActionRestricted={true}*/}
      {/*    showSignInModal={showSignInModal}*/}
      {/*  />*/}
      {/*</SubMenu>*/}
      {/*{store?.session?.userInfo ? (*/}
      {/*  <UserMenu user={store?.session?.userInfo} key="10" />*/}
      {/*) : (*/}
      {/*  <Menu.Item*/}
      {/*    key="10"*/}
      {/*    className="sign-in-item"*/}
      {/*    disabled={true}*/}
      {/*    onClick={handleSignInClick}*/}
      {/*  >*/}
      {/*    <SignIn showSignInModal={showSignInModal} />*/}
      {/*  </Menu.Item>*/}
      {/*)}*/}

      {/*<Menu.Item*/}
      {/*  key="11"*/}
      {/*  className="language-flag"*/}
      {/*  disabled={true}*/}
      {/*  onClick={handleChangeLanguageClick}*/}
      {/*>*/}
      {/*  <LanguageSwitcher language={language} setLanguage={setLanguage} />*/}
      {/*</Menu.Item>*/}
    </Menu>
  )
}
