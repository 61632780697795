import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { PL } from './pl/translations'
import { EN } from './en/translations'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN,
      },
      pl: {
        translation: PL,
      },
    },
  })

i18n.changeLanguage('pl')

export default i18n
