import { useState } from 'react'

type UseSignInModalProps = {}

export const useSignInModal = (props: UseSignInModalProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  const showModal = () => {
    setVisible(true)
  }

  const hideModal = () => {
    setVisible(false)
  }

  return {
    showModal,
    hideModal,
    visible,
  }
}
