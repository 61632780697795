import { Card } from 'antd'

import React, {useEffect, useState} from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import {ServiceProvider} from "../../types";

import './ServiceProviderContainer.less'

export type ServiceProviderContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  serviceProvider: ServiceProvider
}

export const ServiceProviderContainer = (props: ServiceProviderContainerProps) => {
  const [image, setImage] = useState<any>()

  const { t } = useTranslation()

  const { serviceProvider } = props

  useEffect(() => {
    import(`../../assets/photos/${serviceProvider.imageName}`).then((item) => {
      setImage(item.default);
    })
  }, []);

  return (
    <Card
      title={t(`service-providers.${serviceProvider.id}`)}
      className="service-provider-card"
      cover={
        <img
          alt="service-provider-image"
          className="service-provider-image"
          src={image}
        />
      }
      onClick={() => window.open(serviceProvider.url, "_blank")}
      bordered={false}
      hoverable
    >
    </Card>
  )
}
