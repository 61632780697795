import React, { useState } from 'react'

import { ReactSession } from 'react-client-session'

import { ViewportProvider } from '../../hooks/use-viewport'
import { StoreProvider } from "../../hooks/use-store"

import { PageLayout } from '../../layouts/PageLayout/PageLayout'
import { useSignInModal } from '../../hooks/use-sign-in-modal'

import './App.less'

import { repository } from "../../repository/root"

export const App = () => {
  const [language, setLanguage] = useState<string>('pl')
  const { showModal, hideModal, visible } = useSignInModal({})

  ReactSession.setStoreType("localStorage")

  return (
    <StoreProvider repository={repository}>
      <ViewportProvider>
        <div className="App">
          <PageLayout
            language={language}
            setLanguage={setLanguage}
            showSignInModal={showModal}
            hideSignInModal={hideModal}
            isSignInModalVisible={visible}
          />
        </div>
      </ViewportProvider>
    </StoreProvider>
  )
}
