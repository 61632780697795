import React from 'react'
import Flags from 'country-flag-icons/react/3x2'
import i18n from '../../translations/i18n'

import './LanguageSwitcher.less'

type LanguageSwitcherProps = React.HTMLAttributes<HTMLDivElement> & {
  language?: string
  setLanguage: React.Dispatch<React.SetStateAction<string>>
}

export const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const { language, setLanguage } = props

  const handleChangeLanguageClick = () => {
    let newLanguage = 'en'

    if (language === 'en') {
      newLanguage = 'pl'
    }

    setLanguage(newLanguage)
    i18n.changeLanguage(newLanguage)
  }

  return language === 'en' ? (
    <Flags.PL
      className="flag"
      title="Polski"
      onClick={handleChangeLanguageClick}
    />
  ) : (
    <Flags.US
      className="flag"
      title="English"
      onClick={handleChangeLanguageClick}
    />
  )
}
