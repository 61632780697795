import { Carousel } from 'antd'

import { useViewport } from "../../hooks/use-viewport";

import './Gallery.less'

import wedding1 from '../../assets/photos/reportaz_1.jpg'
import wedding2 from '../../assets/photos/reportaz_2.jpg'
import wedding3 from '../../assets/photos/reportaz_3.jpg'
import wedding4 from '../../assets/photos/reportaz_4.jpg'
import wedding5 from '../../assets/photos/wedding_reportage_album.jpg'
import session1 from '../../assets/photos/wedding_outdoor_album.jpg'
import session2 from '../../assets/photos/sesja_2.jpg'

export const Gallery = () => {
  const { galleryVerticalView } = useViewport()

  return (
    <Carousel className={galleryVerticalView ? 'gallery-vertical' : 'gallery'} dotPosition={'bottom'} effect={'fade'} autoplaySpeed={2000} speed={1500} autoplay>
      <div className="gallery-item">
        <img className="gallery-image" src={wedding1} alt="wedding-photo-1" />
      </div>
      <div className="gallery-item">
        <img className="gallery-image" src={wedding2} alt="wedding-photo-2" />
      </div>
      {/*<div className="gallery-item">*/}
      {/*  <img className="gallery-image" src={wedding3} alt="wedding-photo-3" />*/}
      {/*</div>*/}
      <div className="gallery-item">
        <img className="gallery-image" src={wedding4} alt="wedding-photo-4" />
      </div>
      <div className="gallery-item">
        <img className="gallery-image" src={wedding5} alt="wedding-photo-5" />
      </div>
      <div className="gallery-item">
        <img className="gallery-image" src={session1} alt="wedding-session-1" />
      </div>
      <div className="gallery-item">
        <img className="gallery-image" src={session2} alt="wedding-session-2" />
      </div>
      {/*{ !verticalView && (*/}
      {/*  <div className="gallery-item">*/}
      {/*    <img className="gallery-image" src={photo14} alt="gallery-14" />*/}
      {/*  </div>*/}
      {/*)}*/}
    </Carousel>
  )
}
