import { Layout } from 'antd'
import { HeartOutlined } from "@ant-design/icons";

import KurTechLogo from "../../assets/kurtech_logo.png";
import './CustomFooter.less'

const { Footer } = Layout

export const CustomFooter = () => {
  return (
    <Footer style={{ textAlign: 'center' }}>
      Made with <HeartOutlined /> by <a href='https://www.linkedin.com/in/sebastian-kurpios-aa897410b/'><img src={KurTechLogo} alt='KurTech logo'/></a>
   </Footer>
  )
}
