import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react'
import { ReactSession } from 'react-client-session'

import { repository, RootRepository } from "../repository/root";
import  { Session } from "../api/session";
import {UserUnauthorizedError} from "../errors/UserUnauthorizedError";

export type StoreContextType = {
  repository: RootRepository
  session?: Session
  setSession: (session: Session | undefined) => void
  getSession: () => Session | undefined
}

const storeContext = createContext<StoreContextType | null>(null)

type StoreProviderProps = {
  repository: RootRepository
}

export const StoreProvider = (props: PropsWithChildren<StoreProviderProps>) => {
  const [session, setSession] = useState<Session | undefined>(ReactSession.get('session'))

  const setUserSession = (session: Session | undefined) => {
    if (session === undefined) {
      setSession(undefined)
      ReactSession.remove('session')
    }
    else {
      setSession(session)
      ReactSession.set('session', session)
    }
  }

  const getUserSession = (): Session | undefined => {
    return session || ReactSession.get('session')
  }

  const value = {
    repository: props.repository,
    session: session,
    setSession: setUserSession,
    getSession: getUserSession
  }

  return (
    <storeContext.Provider value={value}>
      {props.children}
    </storeContext.Provider>
  )
}

export const useStore = () => {
  const store = useContext(storeContext)
  if (!store) {
    throw new Error("useStore must be used within a StoreProvider.")
  }

  const signIn = async (password: string, email?: string) => {
    const session = await repository.auth.signIn(password, email)

    store.setSession(session)
  }

  const signOut = async () => {
    await store.repository.auth.signOut()

    store.setSession(undefined)
  }

  const fetchUser = async () => {
    try {
      const currentSession = store.getSession()
      const session = await store.repository.auth.fetchAuthenticatedUser()

      session.multimedia = currentSession?.multimedia
      store.setSession(session)
    }
    catch (error) {
      if (error instanceof UserUnauthorizedError) {
        store.setSession(undefined)
      }

      throw error
    }
  }

  const fetchMultimedia = async () => {
    try {
      let session = store.getSession()
      if (!session) {
        return
      }

      session = await repository.multimediaRepository.getAll(session)
      store.setSession(session)
    }
    catch (error) {
      if (error instanceof UserUnauthorizedError) {
        store.setSession(undefined)
      }

      throw error
    }
  }

  return {
    fetchUser,
    fetchMultimedia,
    signIn,
    signOut,
    store
  }
}

