import {Col, Row} from 'antd'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import { Container } from '../Container/Container'

import './WelcomeThanks.less'
import '@culturehq/add-to-calendar/dist/styles.css'
import {useStore} from "../../hooks/use-store";

import React from "react";

type WelcomeThanksProps = {}

export const WelcomeThanks = (props: WelcomeThanksProps) => {
  const { store } = useStore()
  const { t } = useTranslation()

  return (
    <Container className='welcome-thanks'>
      <h1>{t('divider.thanks')}</h1>
      <p>{t('welcome.text')}</p>
    </Container>
  )
}
