import { Api } from "../api/api";
import { Session } from "../api/session";

import { API } from 'aws-amplify';

export class MultimediaRepository {
  constructor(private api: Api) {}

  public async getAll(session: Session){
    const apiName = 'items';
    const path = '/items';

    const response = await API.get(apiName, path, {})
    return new Session(session.sessionInfo, session.userInfo, response);
  }
}
