import React from 'react'

import {Row} from 'antd'
import './Container.less'

type ContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  justify?: "center" | "start" | "end" | "space-around" | "space-between" | "space-evenly"
}

export const Container = (props: ContainerProps) => {
  return (
    <Row justify={props.justify || "center"} className={props.className} gutter={16}>
      {props.children}
    </Row>
  )
}
