import {Col, Row, Button} from 'antd'
import { CameraOutlined } from '@ant-design/icons';
import React from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import './GuestDataSection.less'
import {Container} from "../Container/Container";

export type GuestDataSectionProps = React.HTMLAttributes<HTMLDivElement> & {
  galleryURL: string
}

export const GuestDataSection = (props: GuestDataSectionProps) => {
  const { galleryURL } = props
  const { t } = useTranslation()

  return (
    <Container>
      <Col span={20} flex={1}>
        <Row>
          <Col span={12}>
            <p>
              {t('guest-data.part_1')}<br/>
              {t('guest-data.part_2')}
            </p>
            <p>
              {t('guest-data.part_3')}
            </p>
          </Col>
          <Col span={12}>
            <Button href={galleryURL} target='_blank' type="primary" icon={<CameraOutlined />}>{t('guest-data.button')}</Button>
          </Col>
        </Row>
      </Col>
    </Container>
  )
}
