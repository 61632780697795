import React from 'react'

import { useTranslation } from 'react-i18next'
import { useStore } from "../../hooks/use-store"
import '../../translations/i18n'

import { LogoutOutlined } from '@ant-design/icons'

import { Menu } from 'antd'

import { LanguageSwitcher } from '../../components/LanguageSwitcher/LanguageSwitcher'

import { NavBarProps } from './NavBar'
import './NavBar.less'

export const NavBarDesktop = (props: NavBarProps) => {
  const { language, setLanguage, showSignInModal } = props

  const { store, signOut } = useStore()
  const { t } = useTranslation()
  const handleChangeLanguageClick = () => {}
  const menuMode = 'horizontal'
  const offset = -100
  const duration = 500
  const isUserLoggedIn = store.session !== undefined

  return (
    <Menu className="menu" mode={menuMode} selectable={false}>
      <Menu.Item
        key="sign-in-item"
        className="sign-in-item"
        icon={<LogoutOutlined />}
        onClick={signOut}
        hidden={!store?.session?.userInfo}
      >
        {t('user-menu.sign-out')}
      </Menu.Item>
      <Menu.Item
        key="language-flag"
        className="language-flag"
        disabled={true}
        onClick={handleChangeLanguageClick}
      >
        <LanguageSwitcher language={language} setLanguage={setLanguage} />
      </Menu.Item>
    </Menu>
  )
}
