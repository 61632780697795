import { ServiceProvider } from "./types";

export const serviceProviders: ServiceProvider[] = [
  {
    id: 'wedding_hall',
    imageName: 'wedding_hall.png',
    url: 'https://dwastawy.com'
  },
  {
    id: 'band',
    imageName: 'band.jpg',
    url: 'https://www.bialekruki.com.pl'
  },
  {
    id: 'video',
    imageName: 'video.png',
    url: 'https://pawelbielecki.com.pl'
  },
  {
    id: 'photographer',
    imageName: 'photographer.png',
    url: 'https://tomaszcichon.com'
  },
  {
    id: 'photo_booth',
    imageName: 'photo_booth.png',
    url: 'https://www.instagram.com/km_notofoto'
  },
  {
    id: 'bartender',
    imageName: 'bartender.jpg',
    url: 'https://www.facebook.com/profile.php?id=100083408677107'
  },
]