import {Col, Row} from 'antd'
import {
  ArrowDownOutlined,
} from '@ant-design/icons'

import React from "react";

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import { Container } from '../Container/Container'

import './WelcomeSeeOnceAgain.less'
import '@culturehq/add-to-calendar/dist/styles.css'
import {useStore} from "../../hooks/use-store";

type WelcomeProps = React.HTMLAttributes<HTMLDivElement> & {}

export const WelcomeSeeOnceAgain = (props: WelcomeProps) => {
  const { store } = useStore()
  const { t } = useTranslation()

  return (
    <Container className="welcome-see-once-again">
      <Row>
        <Col>
          <h1>{t('divider.see-once-again')}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <ArrowDownOutlined className="welcome-see-once-again-arrow"/>
        </Col>
      </Row>
    </Container>
  )
}
