import { ReactSession } from "react-client-session";

import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from "axios"
import { Deserializer } from "jsonapi-serializer"
import { Session } from "./session"
import { UserUnauthorizedError } from "../errors/UserUnauthorizedError";

interface ErrorResponse {
  error?: string
  errors: {
    id: string
    status: string
    title: string
    type: string
  }[]
}

export class Api {
  public axios: AxiosInstance
  public parser: Deserializer

  constructor(
    private config: AxiosRequestConfig,
    parser: Deserializer,
  ) {
    const axiosOptions: AxiosRequestConfig = {
      timeout: 20000,
    }

    this.parser = parser

    this.axios = axios.create({
      ...axiosOptions,
      ...config,
    })

    this.setHeaders()
  }

  setHeaders = () => {
    const session = ReactSession.get('session')

    if (session) {
      const sessionInfo = (session as Session).sessionInfo

      // this.axios.defaults.headers.common["access-token"] = sessionInfo.token
      // this.axios.defaults.headers.common["token-type"] = sessionInfo.tokenType
      // this.axios.defaults.headers.common["client"] = sessionInfo.client
      // this.axios.defaults.headers.common["expiry"] = sessionInfo.expiry
      // this.axios.defaults.headers.common["uid"] = sessionInfo.uid
    }
    else {
      this.axios.defaults.headers.common["access-token"] = ''
      this.axios.defaults.headers.common["token-type"] = ''
      this.axios.defaults.headers.common["client"] = ''
      this.axios.defaults.headers.common["expiry"] = ''
      this.axios.defaults.headers.common["uid"] = ''
    }
  }

  get = async <K>(url: string, config?: AxiosRequestConfig): Promise<{ data: K, headers: AxiosRequestHeaders }> => {
    try {
      this.setHeaders()
      const { data, headers } = await this.axios.get<K>(url, config)
      return { data, headers }
    } catch (error) {
      throw Api.parseError(error)
    }
  }

  post = async <K, T>(
    url: string,
    body: K,
    config?: AxiosRequestConfig
  ): Promise<{ data: T, headers: AxiosRequestHeaders }> => {
    try {
      this.setHeaders()
      const { data, headers } = await this.axios.post<T>(url, body, config)
      return { data, headers }
    } catch (error) {
      throw Api.parseError(error)
    }
  }

  patch = async <K, T>(
    url: string,
    body: K,
    config?: AxiosRequestConfig
  ): Promise<{ data: T, headers: AxiosRequestHeaders }> => {
    try {
      this.setHeaders()
      const { data, headers } = await this.axios.patch<T>(url, body, config)
      return { data, headers }
    } catch (error) {
      throw Api.parseError(error)
    }
  }

  put = async <K, T>(
    url: string,
    body: K,
    config?: AxiosRequestConfig
  ): Promise<{ data: T, headers: AxiosRequestHeaders }> => {
    try {
      this.setHeaders()
      const { data, headers } = await this.axios.put<T>(url, body, config)
      return { data, headers }
    } catch (error) {
      throw Api.parseError(error)
    }
  }

  delete = async <T>(url: string, config?: AxiosRequestConfig): Promise<{ data: T, headers: AxiosRequestHeaders }> => {
    try {
      this.setHeaders()
      const { data, headers } = await this.axios.delete<T>(url, config)
      return { data, headers }
    } catch (error) {
      throw Api.parseError(error)
    }
  }

  private static parseError(error: any) {
    if(error === undefined)
      return new Error('errors.undefined')

    const response = Api.getErrorResponse(error as AxiosError)
    if(response === undefined)
      return new Error('errors.undefined')

    if(this.getErrorStatusCode(response) === 401)
      return new UserUnauthorizedError()

    return new Error(Api.getErrorKey(response))
  }

  private static getErrorResponse(error: AxiosError<ErrorResponse>): AxiosResponse<ErrorResponse> | undefined {
    return error?.response
  }

  private static getErrorKey(error: AxiosResponse<ErrorResponse>): string {
    return error.statusText
  }

  private static getErrorStatusCode(error: AxiosResponse<ErrorResponse>) {
    return error?.status
  }
}
