import {Button, Card} from 'antd'

import React, {useEffect} from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import { Movie } from "../../types";

import './MovieContainer.less'

export type MovieContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  movie: Movie
}

export const MovieContainer = (props: MovieContainerProps) => {
  const { t } = useTranslation()

  const { movie } = props

  return (
    <Card title={t(`movies.${movie.id}`)} className="movie-card" bordered={true}>
      <iframe
        className='movie-iframe'
        src={movie.url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Card>
  )
}
