import {Col} from 'antd'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

import { Container } from '../Container/Container'
import { WelcomeSeeOnceAgain } from '../WelcomeSeeOnceAgain/WelcomeSeeOnceAgain'

import './Welcome.less'
import '@culturehq/add-to-calendar/dist/styles.css'
import {useStore} from "../../hooks/use-store";
import {SignInCard} from "../SignInCard/SignInCard";
import React, {useEffect} from "react";
import {WelcomeThanks} from "../WelcomeThanks/WelcomeThanks";
import {useSignIn} from "../SignInModalContainer/hooks/use-sign-in";

type WelcomeProps = {}

export const Welcome = (props: WelcomeProps) => {
  const { store, signIn } = useStore()
  const { t } = useTranslation()

  const {
    handleSubmitPassed,
    handleSubmitFailed,
    handleSubmitClick,
    handleValueChange,
    passwordValid,
    submitButtonDisabled,
    confirmLoading,
    form,
    signInWithQR
  } = useSignIn({ signIn, hideModal: () => {} })

  useEffect(() => {
    signInWithQR()
  }, [signInWithQR])

  const isUserLoggedIn = store.session !== undefined

  return (
    <Container className="welcome">
      <Col span={22} offset={1}>
        <WelcomeThanks/>
        { isUserLoggedIn ?
          <WelcomeSeeOnceAgain /> :
          <SignInCard
            handleSubmitPassed={handleSubmitPassed}
            handleSubmitFailed={handleSubmitFailed}
            handleSubmitClick={handleSubmitClick}
            handleValueChange={handleValueChange}
            passwordValid={passwordValid}
            submitButtonDisabled={submitButtonDisabled}
            confirmLoading={confirmLoading}
            form={form}
          />
        }
      </Col>
    </Container>
  )
}
