import { Api } from "../api/api"
import { AuthenticationService } from "../api/auth"
import { BASE_API_V1_PATH } from "../constants/api";
import { Deserializer } from "jsonapi-serializer";
import { MultimediaRepository } from "./multimedia";

const apiConfig = {
  baseURL: BASE_API_V1_PATH,
}

export class RootRepository {
  public api: Api

  public readonly auth: AuthenticationService
  public readonly multimediaRepository: MultimediaRepository

  constructor() {
    this.api = new Api(
      {
        ...apiConfig,
      },
      new Deserializer({ keyForAttribute: 'camelCase' })
    )

    this.multimediaRepository = new MultimediaRepository(this.api)

    this.auth = new AuthenticationService()
  }
}

export const repository = new RootRepository()
