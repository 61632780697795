import { Auth } from 'aws-amplify';

import { Session } from "./session";
import { SessionInfo } from "./sessionInfo";
import { SignInError } from "../errors/SignInError";
import {UserUnauthorizedError} from "../errors/UserUnauthorizedError";

export class AuthenticationService {
  private static readonly username = 'viewer';

  async signIn(password: string, email?: string): Promise<Session> {
    try {
      const user = await Auth.signIn(email || AuthenticationService.username, password);

      const sessionInfo = new SessionInfo(user.getSignInUserSession().getIdToken().getJwtToken());
      const userInfo = { name: user.username, email: user.attributes.email, phoneNumber: user.attributes.phone_number }
      return new Session(sessionInfo, userInfo)
    }
    catch {
      throw new SignInError();
    }
  }

  async fetchAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();

      const sessionInfo = new SessionInfo(session.getIdToken().getJwtToken());
      const userInfo = { name: user.username, email: user.attributes.email, phoneNumber: user.attributes.phone_number }
      return new Session(sessionInfo, userInfo)
    }
    catch(error) {
      throw new UserUnauthorizedError();
    }
  }

  async signOut(): Promise<boolean> {
    try {
      await Auth.signOut();
      return true;
    } catch (e) {
      return false;
    }
  }
}
