import {Button, Card, FormInstance} from 'antd'

import React, {useEffect} from 'react'

import { useTranslation } from 'react-i18next'
import '../../translations/i18n'
import {SignInForm} from "../SignInForm/SignInForm";
import {useStore} from "../../hooks/use-store";
import {useSignIn} from "../SignInModalContainer/hooks/use-sign-in";

import './SignInCard.less'

export type SignInCardProps = React.HTMLAttributes<HTMLDivElement> & {
  form: FormInstance
  handleSubmitClick: () => void,
  handleSubmitPassed: (values: any) => void
  handleSubmitFailed: () => void
  handleValueChange: (changedValues: any) => void
  passwordValid: boolean
  initialValue?: string,
  confirmLoading: boolean,
  submitButtonDisabled: boolean,
}

export const SignInCard = (props: SignInCardProps) => {
  const { t } = useTranslation()

  const {
    form,
    handleSubmitPassed,
    handleSubmitFailed,
    handleValueChange,
    passwordValid,
    confirmLoading,
    submitButtonDisabled,
    handleSubmitClick
  } = props

  return (
    <Card title={t('sign-in.text')} className="sign-in-card" bordered={true}>
      <SignInForm form={form}
                  handleSubmitPassed={handleSubmitPassed}
                  handleSubmitFailed={handleSubmitFailed}
                  handleValueChange={handleValueChange}
                  passwordValid={passwordValid} />

      <Button
        key="submit"
        type="primary"
        htmlType="submit"
        loading={confirmLoading}
        disabled={submitButtonDisabled}
        onClick={handleSubmitClick}
      >
        {t('sign-in-modal.sign-in')}
      </Button>
    </Card>
  )
}
